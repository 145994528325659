/**
 * Generates a client feed URL by appending the feedUrl as a query parameter.
 *
 * @param {string} feedUrl - The URL of the feed.
 * @returns {string} The client feed URL with the feedUrl encoded as a query parameter.
 */
export function getClientFeedUrl(feedUrl: string): string {
  const encodedFeedUrl = encodeURIComponent(feedUrl);
  return `api/client-feed?feed-url=${encodedFeedUrl}`;
}
