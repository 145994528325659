import { useEffect } from 'react';
import { useLocation } from '@remix-run/react';
import { plansRoute } from '~/services/plugins/web-products';

const SCREEN_LIMIT = 5;

export const getHistory = () => {
  const screensHistory = JSON.parse(
    localStorage?.getItem('screensHistory') || '[]'
  );
  return screensHistory.slice(screensHistory.length - SCREEN_LIMIT);
};
export const setHistory = (newArr: String[]) => {
  localStorage.setItem('screensHistory', JSON.stringify(newArr));
};

const ignorePaths = [plansRoute];

export const useScreensHistory = () => {
  const location = useLocation();
  useEffect(() => {
    try {
      if (ignorePaths.includes(location.pathname)) {
        return;
      }
      const limitedHistory = getHistory();
      limitedHistory.push(location.pathname + location.search);
      setHistory(limitedHistory);
    } catch (error) {}
  }, [location.pathname, location.search]);
};
